const dashboardModuleRoutes = [
  {
    path: '/dashboard/documents/overview',
    name: 'dashboard--super-overview',
    component: () => import('@/app/modules/dashboard/views/SuperOverview.vue'),
  },
];

dashboardModuleRoutes.forEach((route) => {
  route.meta = {
    ...route.meta,
    authRequired: true,
    fullLayout: false,
  };
});

export default dashboardModuleRoutes;
