<template>
  <v-list-item
    class="workspace-list__item"
    dense
    link
    @click="
      loadWorkspace({
        routeName: 'dashboard',
        workspaceId: workspace.id,
      })
    "
  >
    <v-list-item-icon class="mr-1">
      <v-icon
        v-if="isActiveWorkspace"
        color="primary"
        >mdi-check</v-icon
      >
    </v-list-item-icon>

    <v-hover v-slot="{ hover }">
      <v-list-item-title
        class="subtitle-1 font-weight-regular"
        :class="{
          'font-weight-bold': isActiveWorkspace || hover,
        }"
      >
        <span :class="hover ? 'primary--text' : '#000000'">{{ workspace.title }}</span>
      </v-list-item-title>
    </v-hover>

    <template v-if="workspace.is_owner && !isAccDisabled">
      <v-divider
        inset
        vertical
      ></v-divider>

      <v-list-item-action>
        <v-btn
          class="py-0"
          icon
          @click="loadWorkspace"
        >
          <v-menu
            open-on-hover
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  class="py-0"
                  :color="hover ? 'primary' : '#b3c2d6'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-cog-outline
                </v-icon>
              </v-hover>
            </template>

            <v-list>
              <v-list-item
                v-for="workspaceSettingsLink in workspaceSettingsLinks"
                :key="workspaceSettingsLink.id"
                class="workspace-settings-menu__link"
                link
                @click="loadWorkspace({ hash: workspaceSettingsLink.id })"
              >
                <v-list-item-icon class="workspace-settings-menu__icon mr-3">
                  <component :is="workspaceSettingsLink.icon"></component>
                </v-list-item-icon>

                <v-list-item-title>{{ workspaceSettingsLink.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { defineComponent } from 'vue';
import IcoSignatureIdentity from '@/components/svg/menu/IcoSignatureIdentity';
import IcoTeam from '@/components/svg/menu/IcoTeam';
import IcoAppearance from '@/components/svg/menu/IcoAppearance';
import IcoEmails from '@/components/svg/menu/IcoEmails';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import IcoExperimentalFunctions from '@/components/svg/menu/IcoExperimentalFunctions';
import IcoSettings from '@/components/svg/menu/IcoSettings';

export default defineComponent({
  name: 'AppWorkspaceMenuItem',
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isActiveWorkspace() {
      return `${this.workspace?.id}` === `${this.$route.params.workspace_id || this.$route.params.workspaceId}`;
    },
    isAccDisabled() {
      return this.workspace?.owner_disabled;
    },
    workspaceSettingsLinks() {
      return [
        {
          id: 'sign-identity',
          title: this.$t('workspace.identity'),
          icon: IcoSignatureIdentity,
        },
        {
          id: 'team',
          isDisabled: 'legal' !== this.workspace.type,
          title: this.$t('workspace.team.title'),
          icon: IcoTeam,
        },
        {
          id: 'appearance',
          title: this.$t('workspace.branding.title'),
          icon: IcoAppearance,
        },
        {
          id: 'messages',
          title: this.$t('workspace.notifications.title'),
          icon: IcoEmails,
        },
        {
          id: 'verification',
          isDisabled: !FeatureFlags.signiAuthenticationEnabled(this.workspace),
          title: this.$t('general.persons_verification'),
          icon: IcoExperimentalFunctions,
        },
        {
          id: 'settings',
          title: this.$t('general.settings'),
          icon: IcoSettings,
        },
      ]?.filter((ws) => !ws.isDisabled);
    },
  },
  methods: {
    loadWorkspace({ workspaceId = this.workspace?.id, routeName = 'workspaceSettings', hash = '' }) {
      localStorage.setItem('workspace_id', workspaceId);

      return this.$router.push({
        name: routeName,
        params: {
          workspace_id: workspaceId,
        },
        hash: hash ? `#${hash}` : '',
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.workspace-settings-menu__link {
  .workspace-settings-menu__icon {
    color: #b4c1d6;
  }
}

.workspace-settings-menu__link:hover {
  font-weight: bolder;

  .workspace-settings-menu__icon {
    color: #5e239e;
  }
}
</style>
