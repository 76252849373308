'use strict';

import { apiClient } from '@/app/config/appEnvironment';

/**
 * Verify code for bulk sign operation.
 *
 * @param batchId
 * @param code
 * @return {Promise<unknown>}
 */
export async function verifyCodeForBulkSign(batchId, code) {
  return apiClient.put(`api/v1/docs-batch/${batchId}/verify`, { pin: code });
}
