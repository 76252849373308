/**
 * Table defaults
 */
export const numberOfDefaultTableRows = 10;

/**
 * Document preview variables
 */
export const maxAvailablePagesInDocumentPreview = 25;

/**
 * File extensions
 */
export const supportedDocumentFileExtensions = ['pdf', 'docx', 'xlsx', 'odt', 'odf', 'jpg', 'jpeg', 'png'];
export const supportedSignatureFileExtensions = ['png', 'jpeg', 'jpg'];

/**
 * Local storage keys
 */
// TODO - implement this in the future
export const userTokenKey = 'access-token';
export const accessRefreshTokenKey = 'access-refresh-token';
export const userWorkspaceIdKey = 'user-workspace-id';

/**
 * Session storage keys
 */
export const notificationStorageKey = 'notification-storage';
