<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';

const props = defineProps({ value: String });
const emit = defineEmits(['input', 'confirm-pin']);

const pinInputRef = ref(null);
const code = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
});

function submit() {
  emit('confirm-pin');
}

onMounted(() => {
  nextTick(() => {
    if (pinInputRef.value) {
      pinInputRef.value.focus();
    }
  });
});
</script>

<template>
  <div
    class="tw-mx-auto tw-my-10 tw-max-w-80"
    @keyup.enter="submit"
  >
    <v-otp-input
      ref="pinInputRef"
      v-model="code"
      type="number"
      length="4"
      @finish="submit"
    />
  </div>
</template>

<style scoped lang="scss">
:deep(.input-pin) {
  .v-input__slot {
    height: 5rem;
    font-size: 2.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: transparent !important;

    input {
      max-height: 5rem;
    }
  }
}
</style>
