<script setup>
import { getCurrentInstance, ref } from 'vue';
import PinCodeInput from '@/app/modules/signing/components/PinCodeInput.vue';
import { verifyCodeForBulkSign } from '@/app/modules/signing/services/verifyCodeService';

const app = getCurrentInstance();

const props = defineProps({
  currentBatchId: {
    type: [Number, String],
    required: true,
  },
  prefilledCode: {
    type: [Number, String],
    default: null,
  },
});
const emit = defineEmits(['successful-verification', 'close']);

const pinCode = ref(props.prefilledCode);
const isCodeConfirming = ref(false);

function onCancelAction() {
  emit('close');
}

async function onCodeConfirmation() {
  isCodeConfirming.value = true;

  try {
    const response = await verifyCodeForBulkSign(props.currentBatchId, pinCode.value);

    emit('successful-verification', response);
  } catch (err) {
    app.proxy.$notification.error(app.proxy.$t('general.error'));
  } finally {
    isCodeConfirming.value = false;
  }
}
</script>

<template>
  <v-dialog
    :value="true"
    max-width="500px"
    @keydown.esc="onCancelAction"
  >
    <v-card
      class="pa-5 pa-md-10 mx-auto relative"
      max-width="800px"
      elevation="2"
    >
      <div class="tw-absolute tw-right-1 tw-top-1">
        <v-btn
          icon
          @click="onCancelAction"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-row
        class="px-5"
        align="center"
        justify="center"
        no-gutters
      >
        <v-col
          cols="12"
          class="pb-5"
        >
          <h2 class="tw-text-center tw-text-4xl tw-font-medium">
            {{ $t('two_factor_auth.two_factor_auth_heading') }}
          </h2>
        </v-col>

        <v-col
          cols="12"
          class="px-sm-8 px-md-16 px-0 pt-0 text-center"
        >
          <span class="tw-text-md tw-text-font-normal">
            {{ $t('two_factor_auth.enter_pin_heading_text') }}
          </span>
        </v-col>

        <v-col
          cols="12"
          class="py-0 text-center"
          style="max-width: 20rem"
        >
          <PinCodeInput
            ref="inputPin"
            v-model="pinCode"
            @confirmPin="onCodeConfirmation"
          />
        </v-col>

        <v-col
          cols="12"
          class="pb-3 pt-0 text-center"
        >
          <v-btn
            color="primary"
            min-width="10rem"
            x-large
            :loading="isCodeConfirming"
            @click="onCodeConfirmation"
          >
            {{ $t('two_factor_auth.submit_pin') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
