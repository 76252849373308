import { render, staticRenderFns } from "./TemplatesInfoBanner.vue?vue&type=template&id=4bf9d0d4&scoped=true"
import script from "./TemplatesInfoBanner.vue?vue&type=script&setup=true&lang=js"
export * from "./TemplatesInfoBanner.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf9d0d4",
  null
  
)

export default component.exports